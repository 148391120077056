import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar";

const CourseDetail = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { course } = location.state || {};

  console.log("Course Object:", course);

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      <div className="p-8 max-w-6xl mx-auto">
        <section className="mb-10">
          <h2 className="text-3xl font-bold mb-6">Current Module</h2>
          <div className="border p-6 bg-gray-100 rounded-lg">
            <h3 className="text-2xl font-semibold mb-4">Introduction</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: course?.introduction || "",
              }}
            />
            <div className="flex space-x-4">
              {course?.url && (
                <button
                  className="bg-blue-500 text-white px-6 py-2 rounded hover:bg-blue-600"
                  onClick={() =>
                    navigate("/content", {
                      state: {
                        courseName: course?.name,
                        courseUrl: course?.url,
                        courseDetail: course?.desc,
                      },
                    })
                  }
                >
                  Start Learning
                </button>
              )}
            </div>
          </div>
        </section>

        {/* About This Course Section */}
        <section className="mb-10">
          <h2 className="text-3xl font-bold mb-6">About This Course</h2>
          <div
            className="text-lg text-gray-700 mb-6"
            dangerouslySetInnerHTML={{
              __html: course?.desc || "",
            }}
          />
        </section>

        {/* Course Content Section */}
        <section className="mb-10">
          <h2 className="text-3xl font-bold mb-6">Course Content</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: course?.courseContent || "",
            }}
          />
        </section>
      </div>
    </div>
  );
};

export default CourseDetail;
