import React, { useState } from "react";
import Navbar from "../../components/navbar";
import { Button } from "antd"; // Importing Button from antd
import { useLocation } from "react-router-dom";

const Lab = () => {
  const [show, setShow] = useState("react");
  const [isBookDisabled, setIsBookDisabled] = useState(false);
  const [isLabDisabled, setIsLabDisabled] = useState(true);

  // Get the state passed from the CourseDetail component
  const location = useLocation();
  const { courseName, courseUrl, courseDetail } = location.state || {};

  const handleBookClick = () => {
    setShow("book");
    setIsBookDisabled(true);
    setIsLabDisabled(false);
  };

  const handleLabClick = () => {
    setShow("lab");
    setIsLabDisabled(true);
    setIsBookDisabled(false);
  };

  return (
    <div className="min-h-screen flex flex-col">
      <Navbar />
      {/* Display the course name dynamically with buttons */}
      <div
        className="bg-white border-b drop-shadow p-3 px-8 font-semibold text-lg flex justify-between items-center"
        style={{ zIndex: -9 }}
      >
        <span>{courseName || "CompTIA CYSA+ (CS0-003)"}</span>
        {/* <div className="flex space-x-2">
          <Button
            type="primary"
            disabled={isBookDisabled}
            onClick={handleBookClick}
          >
            Book
          </Button>
          <Button
            type="primary"
            disabled={isLabDisabled}
            onClick={handleLabClick}
          >
            Lab
          </Button>
        </div> */}
      </div>

      <div className="grow flex">
        <div className="w-full">
          {/* {show === "lab" && (
            <iframe
              src="https://thai.cyberphoenix.com/direct-link-demo/?content_demo=lab"
              className="h-full w-full"
            ></iframe>
          )}
          {show === "book" && (
            <iframe src={courseUrl} className="h-full w-full"></iframe>
          )} */}
          <iframe src={courseUrl} className="h-full w-full"></iframe>
          <>
            <div className="p-3 text-lg font-medium border-b drop-shadow">
              Course Details
            </div>
            <div className="p-3 space-y-2">
              <h3 className="font-medium">Description</h3>
              {/* Display the passed course description as rich text */}
              <div
                className="rich-text-content"
                dangerouslySetInnerHTML={{
                  __html: courseDetail || "No description available.",
                }}
              />
            </div>
          </>
        </div>
      </div>
    </div>
  );
};

export default Lab;
