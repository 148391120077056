import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getAccessToken, clearAccessToken } from "../../services/apiService";
import logo from "../../assets/logo.png";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is already logged in by verifying the presence of the access token
    const token = getAccessToken();
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const handleLogin = (status) => {
    setIsLoggedIn(status);
  };

  const handleLogout = () => {
    clearAccessToken(); // Clear the token
    setIsLoggedIn(false); // Update the login status
    navigate("/"); // Redirect to home page
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleLoginNavigation = () => {
    navigate("/login");
    window.location.reload(); // Perform a hard refresh
  };

  return (
    <div className="border-b p-5 px-8 drop-shadow-sm bg-blue-500 flex justify-between items-center text-white">
      {/* Logo */}
      <img
        src={logo}
        alt="Logo"
        onClick={() => navigate("/")}
        className="cursor-pointer"
      />

      {/* Navigation Links */}
      <div className="space-x-5">
        <button className="hover:underline" onClick={() => navigate("/")}>
          Home
        </button>
        <button
          className="hover:underline"
          onClick={() => navigate("/courses")}
        >
          Courses
        </button>
        <button className="hover:underline" onClick={() => navigate("#")}>
          About
        </button>
        <button className="hover:underline" onClick={() => navigate("#")}>
          Contact Us
        </button>
      </div>

      {/* Conditional Links for Logged-in Users */}
      <div className="flex space-x-5 items-center">
        {isLoggedIn && (
          <button
            className="hover:underline"
            onClick={() => navigate("/my-courses")}
          >
            My Learnings
          </button>
        )}

        {/* Sign In Button (shown when not logged in) */}
        {!isLoggedIn && (
          <button
            className="bg-white text-blue-500 font-semibold py-2 px-4 rounded hover:bg-gray-200 transition duration-200 ease-in-out"
            onClick={handleLoginNavigation}
          >
            Sign In
          </button>
        )}

        {/* My Profile dropdown (only visible when logged in) */}
        {isLoggedIn && (
          <div className="relative">
            <button className="hover:underline" onClick={toggleDropdown}>
              My Profile
            </button>
            {showDropdown && (
              <div className="absolute right-0 mt-2 w-40 bg-white text-black rounded shadow-lg">
                <button
                  className="block w-full text-left px-4 py-2 hover:bg-gray-100"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Navbar;
