import React, { useEffect, useState } from "react";
import Navbar from "../../components/navbar";
import CourseCard from "../../components/course-card";
import { fetchCourses, getAllResources } from "../../services/apiService";

const MyLearnings = () => {
  const [myCourses, setMyCourses] = useState([]);
  const [labResources, setLabResources] = useState([]); // State for lab resources
  const [exploreCourses, setExploreCourses] = useState([]);
  const [loadingMyCourses, setLoadingMyCourses] = useState(true);
  const [loadingExploreCourses, setLoadingExploreCourses] = useState(true);
  const [errorMyCourses, setErrorMyCourses] = useState(null);
  const [errorExploreCourses, setErrorExploreCourses] = useState(null);
  const [activeTab, setActiveTab] = useState("Courses");

  const loadMyCourses = async () => {
    try {
      const data = await fetchCourses();
      if (data) {
        const courses = data.filter((item) => item.resourceName !== "lab");
        const labs = data.filter((item) => item.resourceName === "lab");
        setMyCourses(courses);
        setLabResources(labs); // Set lab resources
      }
      setLoadingMyCourses(false);
    } catch (err) {
      console.error("Error loading my courses:", err);
      setErrorMyCourses("Failed to load my courses.");
      setLoadingMyCourses(false);
    }
  };

  const loadExploreCourses = async () => {
    try {
      const response = await getAllResources();
      if (response) {
        const mappedCourses = response
          .filter((item) => item.resourceName !== "lab")
          .map((item) => ({
            key: item.key,
            name: item.name,
            desc: item.desc,
            introduction: item.introduction || "No introduction available.",
            courseContent: item.courseContent || "No course content available.",
            url: item.url,
            tag: item.tag,
          }));
        setExploreCourses(mappedCourses);
      }
      setLoadingExploreCourses(false);
    } catch (err) {
      console.error("Error loading explore courses:", err);
      setErrorExploreCourses("Failed to load explore courses.");
      setLoadingExploreCourses(false);
    }
  };

  useEffect(() => {
    loadMyCourses();
    loadExploreCourses();
  }, []);

  if (loadingMyCourses || loadingExploreCourses) {
    return <div>Loading...</div>;
  }

  if (errorMyCourses || errorExploreCourses) {
    return <div>Error: {errorMyCourses || errorExploreCourses}</div>;
  }

  return (
    <div>
      <Navbar />
      <div className="w-2/3 mx-auto">
        <div className="my-10 flex justify-center space-x-8">
          <button
            onClick={() => setActiveTab("Courses")}
            className={`px-6 py-2 font-semibold text-lg ${
              activeTab === "Courses"
                ? "border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
          >
            Courses
          </button>
          <button
            onClick={() => setActiveTab("Lab")}
            className={`px-6 py-2 font-semibold text-lg ${
              activeTab === "Lab"
                ? "border-b-2 border-blue-500"
                : "text-gray-500"
            }`}
          >
            Lab
          </button>
        </div>

        {activeTab === "Courses" ? (
          <>
            <div className="my-20">
              <h1 className="text-4xl">
                My <span className="italic font-semibold">Courses</span>
              </h1>
              <div className="mt-10 grid grid-cols-3 gap-8">
                {myCourses.map((item) => (
                  <CourseCard
                    key={item.key}
                    tag={item.tag}
                    name={item.name}
                    desc={item.desc}
                    introduction={item.introduction}
                    courseContent={item.courseContent}
                    bougth={true}
                    url={item.url}
                  />
                ))}
              </div>
            </div>

            <div className="my-20">
              <h1 className="text-4xl">
                Explore <span className="italic font-semibold">Courses</span>
              </h1>
              <div className="mt-10 grid grid-cols-3 gap-8">
                {exploreCourses.map((item) => (
                  <CourseCard
                    key={item.key}
                    tag={item.tag}
                    name={item.name}
                    desc={item.desc}
                    introduction={item.introduction}
                    courseContent={item.courseContent}
                    bougth={true}
                    url={item.url}
                  />
                ))}
              </div>
            </div>
          </>
        ) : (
          <div className="my-20">
            {labResources.length > 0 ? (
              <div className="grid grid-cols-1 gap-8">
                {labResources.map((lab) => (
                  <div
                    key={lab.key}
                    className="bg-gray-100 rounded-lg shadow-md p-6 h-screen"
                  >
                    {lab.url && (
                      <iframe
                        src={lab.url}
                        title={lab.name}
                        className="w-full h-full"
                        allowFullScreen
                      />
                    )}
                  </div>
                ))}
              </div>
            ) : (
              <p className="text-center text-gray-500">
                No lab resources available.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default MyLearnings;
